import React, {Component} from 'react';
import headshot from "../../headshot.jpg";

class About extends Component{
    render(){
	return(
	    <div className="App-header">
              <div>
                <img src={headshot} alt="headshot" style={{
                    width: "200px",
                    height: "auto",
                    float: "left",
                    objectPosition: "center",
                    shapeOutside: "padding-box",
                    objectFit: "cover",
                    paddingRight: "5px",
                    margin: "60px 5px 5px 0"
                }} />
                
	        <p style={{clear: "auto"}}> <span className="start">H</span> i, my name is Jake Betzold. I recently graduated from Indiana University and I am an aspiring software developer. I really don't have a preference on what kind since I pretty much enjoy whatever I do when it comes to coding.</p>
              
                  <p> I enjoy working with data due to the extrapolations that can be made and the impact that analyzing data can have on a business. That being said, since graduating I have taken a dive into front end development, I'm still learning and that is the purpose of this site!</p>

                  <p> That being said, I like more than just coding, I love reading, games, and world building. Dungeons and Dragons is a way that I combine these things. </p>
                
                  <p> I will be adding entries to the blog section of this site after a bit, some will pertain to DnD, but others will be related to various projects that I undertake from painting to coding to world building.</p>
                </div>
	      </div>
	);
    };
};

export default About;
