import React from 'react';
import "./resume.css";

const Resume = (props) => {
    return (
        <div id="encapsulate">
          <div id="page_1">

            
            <p className="p0 ft0">Steven J Betzold</p>
            <p className="p1 ft1">2562 Lincoln Hill Rd., Martinsville, IN 46151</p>
            <table cellpadding="0" cellspacing="0" className="t0">
              <tr>
                <td colSpan="2" className="tr0 td0"><p className="p2 ft5"><span className="ft2">Email: </span><a href="mailto:betzoldjake@gmail.com"><span className="ft3">​</span></a><a href="mailto:betzoldjake@gmail.com"><span className="ft4">betzoldjake@gmail.com</span></a></p></td>
                <td colSpan="2" className="tr0 td1"><p className="p3 ft2">Phone: (765) <nobr>346-0367</nobr></p></td>
              </tr>
              <tr>
	        <td colSpan="2" className="tr1 td0"><p className="p2 ft2">Linkedin: <nobr>linkedin.com/in/jake-betzold</nobr></p></td>
	        <td colSpan="2" className="tr1 td1"><p className="p2 ft2">Github: github.com/ogrepoker</p></td>
              </tr>
              <tr>
	        <td className="tr2 td2"><p className="p2 ft6">&nbsp;</p></td>
	        <td className="tr2 td3"><p className="p2 ft6">&nbsp;</p></td>
	        <td className="tr2 td4"><p className="p2 ft6">&nbsp;</p></td>
	        <td className="tr2 td5"><p className="p2 ft6">&nbsp;</p></td>
              </tr>
            </table>
            <p className="p4 ft7">EDUCATION</p>
            <table cellPadding="0" cellSpacing="0" className="t1">
              <tr>
	        <td className="tr3 td6"><p className="p2 ft8">Indiana University</p></td>
	        <td className="tr3 td7"><p className="p5 ft8">Bloomington, IN</p></td>
              </tr>
              <tr>
	        <td className="tr4 td6"><p className="p2 ft9">Bachelor of Science in Computer Science</p></td>
	        <td className="tr4 td7"><p className="p5 ft9">May 2020</p></td>
              </tr>
              <tr>
	        <td className="tr1 td6"><p className="p6 ft2">Specializations: Programming Languages and Foundations</p></td>
	        <td className="tr1 td7"><p className="p7 ft2">GPA 3.38</p></td>
              </tr>
              <tr>
	        <td className="tr5 td6"><p className="p2 ft8">Indiana Academy for Science, Mathematics, and Humanities</p></td>
	        <td className="tr5 td7"><p className="p8 ft8">Muncie, IN</p></td>
              </tr>
              <tr>
	        <td className="tr6 td6"><p className="p2 ft9">Indiana Core 40 with Honors</p></td>
	        <td className="tr6 td7"><p className="p9 ft2">May 2017</p></td>
              </tr>
              <tr>
	        <td className="tr6 td6"><p className="p2 ft6">&nbsp;</p></td>
	        <td className="tr6 td7"><p className="p10 ft2">GPA 3.76</p></td>
              </tr>
            </table>
            <p className="p11 ft7">EXPERIENCE</p>
            <table cellPadding="0" cellSpacing="0" className="t2">
              <tr>
	        <td className="tr3 td8"><p className="p2 ft8">School of Informatics, Computing, and Engineering</p></td>
	        <td className="tr3 td9"><p className="p12 ft8">Bloomington, IN</p></td>
              </tr>
              <tr>
	        <td className="tr1 td8"><p className="p2 ft10">Undergraduate Instructor (C311 and C343)</p></td>
	        <td className="tr1 td9"><p className="p2 ft10">August 2019 - May</p></td>
              </tr>
              <tr>
	        <td className="tr7 td8"><p className="p13 ft10">2020</p></td>
	        <td className="tr7 td9"><p className="p2 ft6">&nbsp;</p></td>
              </tr>
            </table>
            <p className="p14 ft2"><span className="ft11">●</span><span className="ft12">Presented the topics of greedy algorithms, dynamic programming, and data structures such as hash maps.</span></p>
            <p className="p14 ft2"><span className="ft11">●</span><span className="ft12">Assisted in the teaching of the concept of interpreters, pattern matching, and macros in Racket.</span></p>
            <p className="p15 ft2"><span className="ft11">●</span><span className="ft12">Facilitated weekly discussion and answer sessions in order to increase students’ understanding of lectures.</span></p>
            <p className="p15 ft2"><span className="ft11">●</span><span className="ft12">Created examples for students to review in weekly discussions for a better understanding of lecture content.</span></p>
            <table cellPadding="0" cellSpacing="0" className="t3">
              <tr>
	        <td className="tr8 td10"><p className="p2 ft8">School of Informatics, Computing, and Engineering</p></td>
	        <td className="tr8 td11"><p className="p16 ft8">Bloomington, IN</p></td>
              </tr>
              <tr>
	        <td className="tr7 td10"><p className="p2 ft10">Tutor</p></td>
	        <td className="tr7 td11"><p className="p2 ft13">September 2018 - May 2020</p></td>
              </tr>
            </table>
            <p className="p14 ft2"><span className="ft11">●</span><span className="ft12">Tailored instruction for better learning and understanding of computer science and informatics courses.</span></p>
            <p className="p14 ft2"><span className="ft11">●</span><span className="ft12">Developed lessons to show a different perspective on course content than presented in students’ classNamees.</span></p>
            <p className="p15 ft2"><span className="ft11">●</span><span className="ft12">Learned topics of which I was unfamiliar in order to better teach students in classNamees I had not taken.</span></p>
            <table cellPadding="0" cellSpacing="0" className="t4">
              <tr>
	        <td className="tr8 td12"><p className="p2 ft8">Stuard & Assoc. LLC</p></td>
	        <td className="tr8 td13"><p className="p17 ft8">Martinsville, IN</p></td>
              </tr>
              <tr>
	        <td className="tr3 td12"><p className="p2 ft10">Consultant</p></td>
	        <td className="tr3 td13"><p className="p2 ft13">September 2018 – Present (Part time as needed)</p></td>
              </tr>
            </table>
            <p className="p14 ft2"><span className="ft11">●</span><span className="ft12">Created a MySQL database to store Illinois state data combined with internal company data.</span></p>
            <p className="p18 ft15"><span className="ft11">●</span><span className="ft14">Scheduled Cron jobs to update aforementioned database based on rolling release of an updated PDF containing new expiration dates from Illinois state website since Illinois does not have an API for the data.</span></p>
            <p className="p14 ft2"><span className="ft11">●</span><span className="ft12">Designed a marketing system connecting to the above database to schedule jobs and contact dates.</span></p>
            <table cellPadding="0" cellSpacing="0" className="t5">
              <tr>
	        <td className="tr8 td14"><p className="p2 ft8">Stuard & Assoc. LLC</p></td>
	        <td className="tr8 td15"><p className="p10 ft8">Martinsville, IN</p></td>
              </tr>
              <tr>
	        <td className="tr3 td14"><p className="p2 ft10">Intern</p></td>
	        <td className="tr3 td15"><p className="p10 ft13">June 2018 – August 2018</p></td>
              </tr>
            </table>
            <p className="p19 ft15"><span className="ft11">●</span><span className="ft14">Implemented a procedure in R to update the ServiceBridge data from Illinois State Elevator Report, this allowed for the company to have more up to date analysis of their customer’s elevator expiration dates.</span></p>
            <p className="p20 ft15"><span className="ft11">●</span><span className="ft14">Imported data from ServiceBridge, through their REST API calls, into PipeDrive to allow for the easy tracking of customer status from start to finish of the job, then updated PipeDrive with ServiceBridge.</span></p>
            <p className="p21 ft7">RESEARCH</p>
            <table cellPadding="0" cellSpacing="0" className="t6">
              <tr>
	        <td className="tr3 td14"><p className="p2 ft8">Quantum Computing</p></td>
	        <td className="tr3 td16"><p className="p2 ft8">June <nobr>2019-August</nobr> 2019</p></td>
              </tr>
            </table>
            <p className="p14 ft2"><span className="ft3">●</span><span className="ft16">Read relevant research papers and taught myself the physics and mathematics behind those papers.</span></p>
            <p className="p14 ft2"><span className="ft3">●</span><span className="ft16">Wrote a framework to begin emulating the popular quantum programming language Qiskit within Scala.</span></p>
            <p className="p14 ft2"><span className="ft3">●</span><span className="ft16">Ran quantum circuits representing entanglement in different ways noting computational speed changes.</span></p>
            <p className="p21 ft7">LEADERSHIP ROLES</p>
            <table cellPadding="0" cellSpacing="0" className="t7">
              <tr>
	        <td className="tr1 td17"><p className="p2 ft8">Director of Finance</p></td>
	        <td className="tr1 td18"><p className="p22 ft8">Indiana University</p></td>
              </tr>
              <tr>
	        <td className="tr3 td17"><p className="p2 ft10">SICE LLC</p></td>
	        <td className="tr3 td18"><p className="p2 ft13">September 2017 - February 2018</p></td>
              </tr>
            </table>
            <p className="p23 ft2"><span className="ft11">●</span><span className="ft17">Designed the semester budget for the SICE LLC, and assisted in implementing said budget.</span></p>
            <p className="p23 ft2"><span className="ft11">●</span><span className="ft17">Managed fund allocation to determine where funds would be spent for activities done by the LLC.</span></p>
            <p className="p23 ft2"><span className="ft11">●</span><span className="ft17">Worked with local businesses to source activity needs, determine cost, and allocate materials for events.</span></p>
            <p className="p24 ft7">TECHNICAL SKILLS</p>
            <p className="p25 ft19"><span className="ft11">●</span><span className="ft18">Programming Languages: C++, Haskell, HTML/CSS, Java, JavaScript (React, Node), MySQL, Pie, Python, R, Racket, Scala, Scheme, PostgreSQL, Qiskit, Qasm</span></p>
            <p className="p23 ft2"><span className="ft11">●</span><span className="ft17">Operating Systems: Windows, Mac, and Linux</span></p>
            <p className="p4 ft7">ADDITIONAL</p>
            <p className="p26 ft2"><span className="ft11">●</span><span className="ft12">Presenter at Nextech Catapult program: Gave a talk about how to move forward in life in post HS years.</span></p>
            <p className="p27 ft20">References available upon request</p>
          </div>
        </div>
    );
};

export default Resume;
