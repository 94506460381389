import React from 'react';
import {
    Card, Button
} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import post1pic from "./thumbnails/post1.svg";
import "./blog.css";

const Blog = (props) => {
    return (
        <div className="App-header">
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={post1pic} />
              <Card.Body>
                <Card.Title>Miniature Painting: Ancient Red Dragon</Card.Title>
                <Card.Text>
                  In this post I discuss how I painted the Deep Cuts Ancient Red Dragon miniature.
                </Card.Text>
                <Link to="blog/posts/1">
                  <Button >
                    <span>Read</span>
                  </Button>
                </Link>
              </Card.Body>
            </Card>
        </div>
    );
};

export default Blog;
