import React from 'react';
import '../blog.css';
import redDragonOnline from "../photos/redDragonOnline.png";
import redDragonBack from "../photos/redDragonBack.png";

const Post1 = (props) => {
    return (
        <div style={{width:"100%", height:"100%",backgroundColor: "#f5f5f5"}}>
          <div className="article">
            <h2 className="articleheader">
              Deep Cuts Red Dragon
            </h2>
            <h2 className="articledate">
              June 11, 2020
            </h2>
            <div className="articlecontent">
              <p> I came across this mini at the game store as I have for most of my collection (support local businesses when you can, they have an atmosphere that many chains don’t offer in my experience). Normally the color scheme of a Red dragon would be red and a somewhat tannish color as you can see below.</p>

              <img src={redDragonOnline} width="100%"/>

              <p>Now I saw this picture (which is from the 5e Monster Manual) and wasn’t really a fan of it, it was too bright for my taste. So I decided to go the opposite route. I chose black for the main accent color and a pinkish tan for the secondary. Obviously red is the main color here.</p>

              <p>The base coat for this mini is red as it is the most prevalent color. I did a mixture of blood red and a metallic based silver to give the red a hint of a shimmer. I like doing this with dragons since the scales would shine if it were an actual thing. I then proceeded to paint the underbelly, using a mix of tan and few drops of red. This ran all the way up the tail which got really small and highly detailed I had to use nothing but <a href="https://www.amazon.com/Army-Painter-Psycho-Brush-Rothmarder/dp/B009CBC0PQ"> The Psycho</a>. </p>

              <p>The hardest part was the spikes. They go all down the back of the mini and were small enough to require super detailed work throughout. I think I spent 3 hours on this part alone. I used a mixture of black and charcoal so that they would be actually black but be thick and chalky like the charcoal paint. The back:</p>
              
              <img src={redDragonBack} width="100%" />
              
              <p>I unfortunately moved recently and during the transit the paint was chipped in places. But that just means I get to work on small detail even more!</p>
              
              <p>The last part of this mini was the wings. I had the base coat of red from before, but I wanted them to be more ominous than that (or going to a lighter tint, though now that I think about that it would be super cool to go red to orange to white to mimic flame on the ends). So I did a mix of red was and black wash, increasing the ratio of black to red as I got further down the wing. Keep in mind here that I went from the starting point to the tips for each said ratio.</p>
              
              <p>That’s all on how I did this mini. Next I think that I will have a post on either world building or coding.</p>
              
              <p> Peace. </p>
            </div>
          </div>
        </div>
    );
};

export default Post1;
