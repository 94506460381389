import React, { useState } from 'react';
import {
  Collapse,
  Navbar as BootstrapNavBar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link as ReactLink, BrowserRouter as Router, Route, Switch}  from 'react-router-dom';
import {About, Blog, Resume, Main} from "./components";
import Post1 from "./components/blog/posts/Post1";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.css';


const Navbar = (props) => {
    const [value, setValue] = useState("> ");

    const [visible, setVisible] = useState(false);

    const handleKeyPress = (evt) => {
        const helpText = "Hello, welcome to the jakebetzold.com navigation terminal! We are currently in the early stages of release and apologize for any inconvieniences had. \n That being said, currently we have one operation availiable, \"cd\". \n\n You can type \"cd about\" to take you to the about page, or any other page that we have availiable! Have fun and thank you for visiting!";
        if(evt.key === 'Enter'){
            console.log(value);
            var reg = />\s* cd\s* (about|resume|github|home|blog)/gi;
            var help = />\s* help()/;
            if(reg.test(value)){
                var res = value.split(reg)[1];
                setValue((value+"\n"+res+"\n>").trim());
            }else if(help.test(value)){
                setValue((value+"\n"+helpText+"\n>").trim());
            }else if(value == "> ls"){
                setValue((value+"\n"+"about resume blog"+"\n>"));
            }else{
                var res = "The command you have entered is incorrect, please try again.";
                setValue((value+"\n"+res+"\n>").trim());
            }
        };
    };

    return (
        <div className="ourNavBar" >
          <div className="prompt" onClick={()=> setVisible(!visible)}>
            &gt; Terminal navigation feature coming soon!
          </div>
          {false && (//visible && (
              <textarea
                className="terminal"
                cols="30"
                name=""
                rows="10"
                value={value}
                onChange={evt => setValue(evt.target.value)}
                onKeyPress={handleKeyPress}>
              </textarea>
          )}
          <BootstrapNavBar {...props} />  
        </div>
        
    );
};

const App = (props) => {
    const stickyNav = {
	position: "sticky",
	top: 0
    };
   
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

    return (
	    <div style={stickyNav}>
	      <Router>
	        <Navbar color="light" light expand="md">
        <NavbarBrand href="/"><span className="start-green">J</span>ake <span style={{color:"#096b23"}}>B</span>etzold</NavbarBrand>
                  <NavbarToggler onClick={toggle} />
                  <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                      <NavItem>
                        <NavLink tag={ReactLink} to="/about"><span className="start-green">A</span>bout</NavLink>
                      </NavItem>
	              <NavItem>
	                <NavLink tag={ReactLink} to="/resume"><span className="start-green">R</span>esume</NavLink>
	              </NavItem>
                      <NavItem>
                        <NavLink href="https://github.com/Ogrepoker"><span className="start-green">G</span>itHub</NavLink>
                      </NavItem>
                      <NavItem>
	                <NavLink tag={ReactLink} to="/blog"><span className="start-green">B</span>log</NavLink>
	              </NavItem>
                    </Nav>
                  </Collapse>
	        </Navbar>
	        <Switch>
                  <Route path="/resume" component={Resume} />
                  <Route path="/blog/posts/1" component={Post1} />
                  <Route path="/blog" component={Blog} />
                  <Route path="/about" component={About} />
                  <Route path="/" component={Main} />
                  
                </Switch>
	      </Router>
	    </div>
    );
};

export default App;
