import React from 'react';

function App() {
  return (
      <div className="App">
	<header className="App-header">
	  <div className="App-Nametag">
	    <h3 className="namePlate">Jake Betzold</h3>
	    <p className="internalStartPage"> <span className="start-green">R</span>ecent graduate. <span className="start-green">A</span>spiring developer.</p>
	  </div>
	</header>
      </div>
  );
};

export default App;
